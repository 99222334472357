<template>
  <div
    class="start-slideshow-button"
    :class="{
      'start-slideshow-button--inverted': inverted,
      'start-slideshow-button--expanded': isExpanded,
    }"
    @mouseenter="setExpanded(true)"
    @mouseleave="setExpanded(false)"
  >
    <icon
      name="mdi:presentation-play"
      size="16"
      class="start-slideshow-button__icon"
    />
    <transition name="fade-quick-timeout">
      <span
        v-if="isExpanded"
        class="start-slideshow-button__text"
      >
        {{ $t('start_slideshow') }}
      </span>
    </transition>
  </div>
</template>

<script setup>
  defineProps({
    inverted: {
      type: Boolean,
      default: false,
    },
  })

  const isExpanded = ref(false)
  function setExpanded(bool) {
    isExpanded.value = bool
  }
</script>

<style lang="scss">
  .start-slideshow-button {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    background: rgba(255,255,255,.75);
    opacity: 0.6;
    border-radius: 4px;
    transition: all .2s linear .2s;
    height: 44px;
    width: 100%;
    max-width: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    gap: 14px;
    padding: 0 14px;
  }

  .start-slideshow-button--inverted {
    color: var(--color-secondary);
    background: transparent;
    border: 1px solid var(--color-secondary);
  }

  .start-slideshow-button--inverted.start-slideshow-button--expanded {
    background: var(--color-secondary);
    color: var(--color-primary);
  }

  .start-slideshow-button--expanded {
    max-width: 180px;
    opacity: 1;
    background: var(--color-primary);
    color: var(--color-secondary);
  }
</style>
