<template>
  <div
    class="open-event-settings-button"
    :class="{
      'open-event-settings-button--inverted': inverted,
      'open-event-settings-button--expanded': isExpanded,
    }"
    @mouseenter="setExpanded(true)"
    @mouseleave="setExpanded(false)"
  >
    <icon
      name="simple-line-icons:settings"
      size="16"
      class="open-event-settings-button__icon"
    />
    <transition name="fade-quick-timeout">
      <span
        v-if="isExpanded"
        class="open-event-settings-button__text"
      >
        {{ $t('account.events.event.action.settings.title') }}
      </span>
    </transition>
  </div>
</template>

<script setup>
  defineProps({
    inverted: {
      type: Boolean,
      default: false,
    },
  })

  const isExpanded = ref(false)
  function setExpanded(bool) {
    isExpanded.value = bool
  }
</script>

<style lang="scss">
.open-event-settings-button {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background: rgba(255,255,255,.75);
  opacity: 0.6;
  border-radius: 4px;
  transition: all .2s linear .2s;
  height: 44px;
  width: 100%;
  max-width: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  gap: 14px;
  padding: 0 14px;
}

.open-event-settings-button--inverted {
  color: var(--color-secondary);
  background: transparent;
  border: 1px solid var(--color-secondary);
}

.open-event-settings-button--inverted.open-event-settings-button--expanded {
  background: var(--color-secondary);
  color: var(--color-primary);
}

.open-event-settings-button--expanded {
  max-width: 250px;
  opacity: 1;
  background: var(--color-primary);
  color: var(--color-secondary);
}
</style>
