<template>
  <div
    class="fixed-ratio"
    :style="{
      paddingBottom: ratio
    }"
  >
    <div class="fixed-ratio__content">
      <slot />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    width: {
      type: [Number, String],
      default: 2,
    },
    height: {
      type: [Number, String],
      default: 3,
    },
  })

  const ratio = computed(() => `${(props.height / props.width) * 100}%`)
</script>

<style lang="scss" scoped>
  .fixed-ratio {
    display: block;
    position: relative;
    height: 0;
    overflow: hidden;
  }

  .fixed-ratio__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: inherit;
  }
</style>
