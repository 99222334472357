<template>
  <div
    class="event-view"
    :class="{
      'event-view--landscape': isLandscape || forceLandscape,
      'event-view--force-portrait': isLandscape && forcePortrait,
    }"
    :style="backgroundImageStyle"
  >
    <video
      v-if="backgroundIsVideo"
      :src="backgroundImage"
      autoplay
      muted
      loop
      playsinline
      class="event-view__background-video"
    />
    <div class="event-view__options">
      <open-event-settings-button
        v-if="eventSettingsButtonVisible"
        class="event-view__event-settings"
        @click="$emit('openEventSettings')"
      />
      <start-slideshow-button
        v-if="slideshowButtonVisible"
        class="event-view__start-slideshow"
        name="mdi:presentation-play"
        @click="$emit('startSlideshow')"
      />
    </div>
    <icon-button
      v-if="editMode"
      class="event-view__edit-upload event-view__edit-upload--background"
      name="la:edit-solid"
      v-bind="{ smallest: isMobile, small: !isMobile }"
      @click="$emit('editBackground')"
    />
    <div
      class="event-view__upload-wrapper"
    >
      <fixed-ratio
        v-if="driveConnected || editMode"
        :width="1"
        :height="1"
        class="event-view__fixed-ratio"
      >
        <div class="event-view__upload-image-wrapper">
          <img
            v-if="uploadBackgroundImage"
            :src="uploadBackgroundImage"
            class="event-view__background"
            alt="upload background"
          >
          <span
            class="event-view__description"
            :style="[textColorStyle, textFontStyle]"
          >
            {{ uploadText }}
          </span>
          <slot name="app-upload" />
          <icon-button
            v-if="editMode"
            class="event-view__edit-upload"
            name="la:edit-solid"
            v-bind="{ smallest: isMobile, small: !isMobile }"
            @click="$emit('editUpload')"
          />
        </div>
      </fixed-ratio>

      <div v-else class="event-view__warning">
        <app-alert
          type="warning"
        >
          <span v-html="$t('no_drive_connected', { eventId: event.id })" />
        </app-alert>
      </div>
    </div>
    <button
      v-if="!editMode && galleryEnabled && showScrollToImagesButton"
      class="event-view__down-icon"
      @click="scrollDown"
    >
      <icon size="40" name="line-md:chevron-left-circle" />
    </button>
  </div>
</template>

<script>
  import { useToastStore } from '~/stores/toast'
  import { Event } from '~/lib/models/event'
  import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'
  import { mapState } from 'pinia'
  import { useMainStore } from '~/stores/main'
  import IconButton from '~/components/forms/icon-button'

  export default {
    components: { IconButton },
    props: {
      event: {
        type: Event,
        required: true,
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      forceLandscape: {
        type: Boolean,
        default: false,
      },
      forcePortrait: {
        type: Boolean,
        default: false,
      },
      showScrollToImagesButton: {
        type: Boolean,
        default: false,
      },
      slideshowButtonVisible: {
        type: Boolean,
        default: false,
      },
      eventSettingsButtonVisible: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['editBackground', 'editUpload', 'scrollToImages', 'startSlideshow', 'openEventSettings'],
    computed: {
      ...mapState(useMainStore, {
        isMobile: state => state.isMobile,
        isLandscape: state => state.isLandscape,
      }),
      eventId() {
        return this.$route.params.event
      },
      uploadText() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.UPLOAD_TEXT)?.value
      },
      textColor() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.TEXT_COLOR)?.value
      },
      textFontFamily() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.FONT)?.value
      },
      uploadBackgroundImage() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD)?.value
      },
      backgroundImage() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.BACKGROUND_IMAGE)?.value
      },
      backgroundColor() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.BACKGROUND_COLOR)?.value
      },
      galleryEnabled() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.ENABLE_GALLERY)?.value
      },
      backgroundImageStyle() {
        if (!this.backgroundImage || this.backgroundIsVideo) {
          return `background-color: ${this.backgroundColor}`
        }

        return `background: ${this.backgroundColor} url('${this.backgroundImage}'); background-repeat: no-repeat; background-position: center; background-size: cover`
      },
      backgroundIsVideo() {
        return this.backgroundImage?.includes('-video.') ?? false
      },
      textColorStyle() {
        return `color: ${this.textColor}`
      },
      textFontStyle() {
        return `font-family: ${this.textFontFamily}`
      },
      useGoogleDrive() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.USE_GOOGLE_DRIVE)?.value
      },
      useDropbox() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.USE_DROPBOX)?.value
      },
      useHosting() {
        return this.$eventService.getEventPropertyByKey(this.event.properties, EVENT_PROPERTY_KEY.HOSTING_ENABLED)?.value
      },
      driveConnected() {
        return this.useGoogleDrive || this.useDropbox || this.useHosting
      },
    },
    watch: {
      textFontFamily: {
        handler() {
          this.$googleService.setGoogleFonts([this.textFontFamily])
        },
        immediate: true,
      },
    },
    methods: {
      showToastMessage(message) {
        useToastStore().showToastMessage(message)
      },
      scrollDown() {
        this.$emit('scrollToImages')
      },
    },
  }
</script>

<style lang="scss">
.event-view {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--event-background-color, '#f9f9f9');
  position:relative;
}

.event-view__background-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-view__upload-wrapper,
.event-view--landscape.event-view--force-portrait .event-view__upload-wrapper {
  width: 75%;
}

.event-view--landscape .event-view__upload-wrapper {
  width: 35%;
}

.event-view__upload-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  container-type: size;
}

.event-view__description {
  white-space: pre-wrap;
  font-size: 9cqw;
  position: absolute;
  max-width: 95%;
  text-align: center;
  color: var(--event-text-color, var(--color-primary));
}

.event-view--landscape .event-view__description {
  font-size: 8cqw;
}

.event-view__background {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.event-view__options {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.event-view__edit-upload {
  position: absolute;
  top: 10px;
  right: 10px;
}

.event-view__edit-upload {
  z-index: z('edit_icon');
}

.event-view__down-icon {
  transform: rotate(-90deg);
  position: absolute;
  bottom: 40px;
  color: var(--event-text-color, var(--color-primary))
}

.event-view--landscape .event-view__down-icon {
  bottom: 15px;
}

.event-view__warning {
  background: black;
}
</style>
